<template>
  <iframe class="externalLinks" width="100%" height="100%" :src="src" frameborder="0"></iframe>
</template>
<script>
export default {
  name: "externalLinks",
  data() {
    return {
      src: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$options.name = to.name
      vm.src = to.meta.ExternalLinks;
    });
      
  },
  mounted() {
    this.src = this.$route.meta.ExternalLinks;
  },
};
</script>

<style lang="scss">
.externalLinks {
  width: 100%;
  height: calc(100% - 4px);
  overflow: hidden;
}
</style>